import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const CsSvg = styled(Svg)`
  width: 16px;
  height: 16px;
  @media screen and (max-width: 768px){
    width: 12px;
    height: 12px;
  }
`

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
    return (
        <CsSvg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.39953 7.99986C8.39953 8.64153 8.15204 9.28319 7.6662 9.76903L1.68953 15.7457C1.4237 16.0115 0.983702 16.0115 0.717869 15.7457C0.452035 15.4799 0.452035 15.0399 0.717869 14.774L6.69454 8.79736C7.13454 8.35736 7.13454 7.64236 6.69454 7.20236L0.717869 1.22569C0.452035 0.959862 0.452035 0.51986 0.717869 0.254027C0.983702 -0.0118062 1.4237 -0.0118062 1.68953 0.254027L7.6662 6.23069C8.15204 6.71653 8.39953 7.35819 8.39953 7.99986Z" fill="#02111B" />
        </CsSvg>
    );
};

export default Icon;
