import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {

  const CsSvg = styled(Svg)`
    width: 14px;
    height: 14px;
    margin-left: 6px;
  @media screen and (max-width: 600px) {
    width: 11px;
    height: 11px;
    margin-left: 4px;
  }
`;

  return (
  <CsSvg viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.933333 1.59476e-06C0.685798 1.59476e-06 0.448401 0.0983346 0.273367 0.273369C0.098333 0.448402 0 0.685799 0 0.933335V9.33334C0 9.58087 0.098333 9.81827 0.273367 9.9933C0.448401 10.1683 0.685798 10.2667 0.933333 10.2667H9.33333C9.58087 10.2667 9.81827 10.1683 9.9933 9.9933C10.1683 9.81827 10.2667 9.58087 10.2667 9.33334V6.06667C10.2667 5.9429 10.2175 5.8242 10.13 5.73668C10.0425 5.64917 9.92377 5.6 9.8 5.6C9.67623 5.6 9.55753 5.64917 9.47002 5.73668C9.3825 5.8242 9.33333 5.9429 9.33333 6.06667V9.33334H0.933333V0.933335H4.2C4.32377 0.933335 4.44247 0.884168 4.52998 0.796651C4.6175 0.709134 4.66667 0.590436 4.66667 0.466668C4.66667 0.342901 4.6175 0.224202 4.52998 0.136685C4.44247 0.0491682 4.32377 1.59476e-06 4.2 1.59476e-06H0.933333ZM10.1304 0.136268C10.2172 0.223268 10.2661 0.340986 10.2667 0.463868V3.26667C10.2667 3.39044 10.2175 3.50913 10.13 3.59665C10.0425 3.68417 9.92377 3.73334 9.8 3.73334C9.67623 3.73334 9.55753 3.68417 9.47002 3.59665C9.3825 3.50913 9.33333 3.39044 9.33333 3.26667V1.5932L4.5304 6.39707C4.48701 6.44046 4.4355 6.47488 4.37881 6.49836C4.32212 6.52184 4.26136 6.53392 4.2 6.53392C4.13864 6.53392 4.07788 6.52184 4.02119 6.49836C3.9645 6.47488 3.91299 6.44046 3.8696 6.39707C3.82621 6.35368 3.79179 6.30217 3.76831 6.24548C3.74483 6.18879 3.73274 6.12803 3.73274 6.06667C3.73274 6.00531 3.74483 5.94455 3.76831 5.88786C3.79179 5.83117 3.82621 5.77966 3.8696 5.73627L8.67347 0.933335H7C6.87623 0.933335 6.75753 0.884168 6.67002 0.796651C6.5825 0.709134 6.53333 0.590436 6.53333 0.466668C6.53333 0.342901 6.5825 0.224202 6.67002 0.136685C6.75753 0.0491682 6.87623 1.59476e-06 7 1.59476e-06H9.8C9.86133 -0.0001589 9.92209 0.0117962 9.97879 0.0351806C10.0355 0.0585651 10.087 0.0929184 10.1304 0.136268Z" fill="#13486B"/>
  </CsSvg>
  );
};

export default Icon;

