import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
  <Svg mt='0.45rem' width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.27083 12.5417C2.81167 12.5417 0 9.73 0 6.27083C0 2.81167 2.81167 0 6.27083 0C9.73 0 12.5417 2.81167 12.5417 6.27083C12.5417 9.73 9.73 12.5417 6.27083 12.5417ZM6.27083 0.875C3.29583 0.875 0.875 3.29583 0.875 6.27083C0.875 9.24583 3.29583 11.6667 6.27083 11.6667C9.24583 11.6667 11.6667 9.24583 11.6667 6.27083C11.6667 3.29583 9.24583 0.875 6.27083 0.875Z" fill="#8C8D8E"/>
    <path d="M6.27246 7.29167C6.03329 7.29167 5.83496 7.09333 5.83496 6.85417V3.9375C5.83496 3.69833 6.03329 3.5 6.27246 3.5C6.51163 3.5 6.70996 3.69833 6.70996 3.9375V6.85417C6.70996 7.09333 6.51163 7.29167 6.27246 7.29167Z" fill="#8C8D8E"/>
    <path d="M6.27083 9.18914C6.195 9.18914 6.11917 9.17164 6.04917 9.14247C5.97917 9.11331 5.915 9.07247 5.85667 9.01997C5.80417 8.96164 5.76333 8.90331 5.73417 8.82747C5.705 8.75747 5.6875 8.68164 5.6875 8.60581C5.6875 8.52997 5.705 8.45414 5.73417 8.38414C5.76333 8.31414 5.80417 8.24997 5.85667 8.19164C5.915 8.13914 5.97917 8.09831 6.04917 8.06914C6.18917 8.01081 6.3525 8.01081 6.4925 8.06914C6.5625 8.09831 6.62667 8.13914 6.685 8.19164C6.7375 8.24997 6.77833 8.31414 6.8075 8.38414C6.83667 8.45414 6.85417 8.52997 6.85417 8.60581C6.85417 8.68164 6.83667 8.75747 6.8075 8.82747C6.77833 8.90331 6.7375 8.96164 6.685 9.01997C6.62667 9.07247 6.5625 9.11331 6.4925 9.14247C6.4225 9.17164 6.34667 9.18914 6.27083 9.18914Z" fill="#8C8D8E"/>
  </Svg>
  );
};

export default Icon;
