import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.4264 14.1333H4.57304C3.81304 14.1333 3.19971 13.5199 3.19971 12.7599V3.16661C3.19971 2.40661 3.81304 1.79327 4.57304 1.79327H11.4264C12.1864 1.79327 12.7997 2.40661 12.7997 3.16661V12.7599C12.7931 13.5199 12.1797 14.1333 11.4264 14.1333Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.26025 4.57324H5.94692" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 4.57324H10.74" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.26025 8H5.94692" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 8H10.74" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M5.26025 11.4268H5.94692" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 11.4268H10.74" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
};

export default Icon;
