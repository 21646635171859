import React from "react";
import styled from "styled-components";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
  <CsSvg viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="13" cy="13" r="13" fill="#EE4437" fillOpacity="0.12"/>
    <circle cx="13" cy="13" r="8" fill="#EE4437"/>
    <path d="M13 14.2C12.672 14.2 12.4 13.928 12.4 13.6V9.6C12.4 9.272 12.672 9 13 9C13.328 9 13.6 9.272 13.6 9.6V13.6C13.6 13.928 13.328 14.2 13 14.2Z" fill="white"/>
    <path d="M13.0002 16.8011C12.8962 16.8011 12.7922 16.7771 12.6962 16.7371C12.6002 16.6971 12.5122 16.6411 12.4322 16.5691C12.3602 16.4891 12.3042 16.4091 12.2642 16.3051C12.2242 16.2091 12.2002 16.1051 12.2002 16.0011C12.2002 15.8971 12.2242 15.7931 12.2642 15.6971C12.3042 15.6011 12.3602 15.5131 12.4322 15.4331C12.5122 15.3611 12.6002 15.3051 12.6962 15.2651C12.8882 15.1851 13.1122 15.1851 13.3042 15.2651C13.4002 15.3051 13.4882 15.3611 13.5682 15.4331C13.6402 15.5131 13.6962 15.6011 13.7362 15.6971C13.7762 15.7931 13.8002 15.8971 13.8002 16.0011C13.8002 16.1051 13.7762 16.2091 13.7362 16.3051C13.6962 16.4091 13.6402 16.4891 13.5682 16.5691C13.4882 16.6411 13.4002 16.6971 13.3042 16.7371C13.2082 16.7771 13.1042 16.8011 13.0002 16.8011Z" fill="white"/>
  </CsSvg>
  );
};

const CsSvg = styled(Svg)`
  width: 26px;
  height: 26px;
  @media screen and (max-width: 768px){
    width: 20px;
    height: 20px;
  }
`
export default Icon;
